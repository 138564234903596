import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
    providedIn: 'root'
})
export class ResultadosService {
    constructor (public afDB:AngularFirestore){
    }
    public guadarRespuesta(idpregunta, idrespuesta){

        return this.afDB.collection("resultados/").add({
            idpregunta: parseInt(idpregunta),
            idrespuesta: parseInt(idrespuesta)
          });
    }
}